import React from 'react';
// Modules
import {
  Button, Center, Container, Heading, Stack, Text, VStack,
} from '@chakra-ui/react';
import Link from 'next/link';
// Utils
import routes from '../../utils/routes';

const NotFound: React.FC = () => (
  <Center h="75vh">
    <Container>
      <Stack direction={['column', 'row']} spacing="8">
        <img className="img" alt="404" src="/images/404.svg" />
        <VStack alignItems="flex-start" spacing="8">
          <VStack alignItems="flex-start">
            <Heading as="h1">404</Heading>
            <Text>Maaf, kami tidak bisa menemukan halaman yang Anda cari.</Text>
          </VStack>
          <Link href={routes.home.href} passHref>
            <Button as="a" colorScheme="primary" textColor="black">
              Halaman Utama
            </Button>
          </Link>
        </VStack>
      </Stack>
    </Container>
    <style jsx>
      {`
        .img {
          height: 160px;
          width: auto;
        }
      `}
    </style>
    ;
  </Center>
);

export default NotFound;
